<template>
  <div class="sm:container">
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("pages.manageOrganisations.title") }}
        </template>
        <template v-slot:description>
          {{ $t("pages.manageOrganisations.description") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            v-if="$can('write:organisations')"
            size="medium"
            variant="fill"
            text="Create organisation"
            @buttonClick="openModalCreateOrganisations"
          >
            <template #iconLeft>
              <IconPlus />
            </template>
          </BaseButton>
        </template>
      </TableHeader>
      <OrganisationsTableLegend />
      <div v-show="organisationsLoading">
        <LoadingIndicator class="mx-auto mt-8 w-8" />
      </div>
      <div v-show="!organisationsLoading">
        <OrganisationsTableRow
          v-for="organisation in organisations"
          :key="organisation.uuid"
          :organisation="organisation"
          @open="openModalDetailsOrganisations"
        />
      </div>
    </TableFrame>
    <transition name="fade">
      <OrganisationsModalCreate
        v-if="modalOrganisationsCreateOpen"
        :country-options="countries"
        :user-options="users"
        @close="closeModal"
        @updateUserUuids="refetchUsers"
      />
      <OrganisationsModalDetails
        v-if="modalOrganisationsDetailsOpen"
        :organisation="organisationDetails"
        :country-options="countries"
        :user-options="users"
        @close="closeModal"
        @updateUserUuids="refetchUsers"
      />
    </transition>
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import OrganisationsTableLegend from "@/components/user-management/organisations/OrganisationsTableLegend";
import OrganisationsTableRow from "@/components/user-management/organisations/OrganisationsTableRow";
import OrganisationsModalCreate from "@/components/user-management/organisations/OrganisationsModalCreate";
import OrganisationsModalDetails from "@/components/user-management/organisations/OrganisationsModalDetails";

import FIND_ORGANISATIONS from "@/graphql/FindOrganisations.gql";
import FIND_COUNTRIES from "@/graphql/FindCountries.gql";
import FIND_USERS_WITHOUT_ORGANISATION from "@/graphql/FindUsersWithoutOrganisation.gql";

export default {
  name: "ManageOrganisations",
  components: {
    TableFrame,
    TableHeader,
    OrganisationsTableLegend,
    OrganisationsTableRow,
    OrganisationsModalCreate,
    OrganisationsModalDetails,
  },
  data() {
    return {
      error: null,
      organisations: [],
      users: [],
      modalOrganisationsCreateOpen: false,
      modalOrganisationsDetailsOpen: false,
      organisationDetails: "",
    };
  },

  computed: {
    organisationsLoading() {
      return this.$apollo.queries.organisations.loading;
    },
  },

  apollo: {
    organisations: {
      query: FIND_ORGANISATIONS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    countries: {
      query: FIND_COUNTRIES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    users: {
      query: FIND_USERS_WITHOUT_ORGANISATION,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  methods: {
    refetchUsers() {
      this.$apollo.queries.users.refetch();
    },
    openModalCreateOrganisations() {
      this.modalOrganisationsCreateOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    openModalDetailsOrganisations(organisation) {
      this.organisationDetails = organisation;
      this.modalOrganisationsDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalOrganisationsCreateOpen = false;
      this.modalOrganisationsDetailsOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
