<template>
  <TableLegendFrame>
    <template v-slot:colOne>
      {{ $t("global.franchisee") }}
    </template>
    <template v-slot:colTwo>
      {{ $t("global.organisation") }}
    </template>
    <template v-slot:colThree>
      {{ $t("global.stores") }}
    </template>
    <template v-slot:colFour>
      {{ $t("global.users") }}
    </template>
  </TableLegendFrame>
</template>

<script>
import TableLegendFrame from "@/components/user-management/TableLegendFrame";

export default {
  components: {
    TableLegendFrame,
  },
};
</script>
