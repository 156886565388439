<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{
            $t(
              "components.userManagement.organisations.organisationsModalCreate.createOrganisation",
            )
          }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent class="flex-grow overflow-y-scroll">
        <OrganisationsForm
          :country-options="countryOptions"
          :user-options="userOptions"
          @updateName="updateName"
          @updateCountryUuid="updateCountryUuid"
          @updateUserUuids="updateUserUuids"
        />
        <div
          v-if="formError"
          class="bg-danger-0 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </ModalSideContent>

      <ModalSideActions class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`global.saveChanges`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="submit"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";
import OrganisationsForm from "@/components/user-management/organisations/OrganisationsForm";

import CREATE_ORGANISATION from "@/graphql/CreateOrganisation.gql";
import FIND_ORGANISATIONS from "@/graphql/FindOrganisations.gql";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    OrganisationsForm,
  },

  props: {
    countryOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formError: null,
      name: null,
      countryUuid: null,
      userUuids: [],
    };
  },

  methods: {
    updateName(name) {
      this.name = name;
    },
    updateCountryUuid(countryUuid) {
      this.countryUuid = countryUuid;
    },
    updateUserUuids(userUuids) {
      this.userUuids = userUuids;
      this.$emit("updateUserUuids");
    },
    closeModal() {
      this.$emit("close");
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: CREATE_ORGANISATION,
          variables: {
            name: this.name,
            countryUuid: this.countryUuid,
            userUuids: this.userUuids,
          },
          update: (store, { data: { createdOrganisation } }) => {
            const data = store.readQuery({
              query: FIND_ORGANISATIONS,
            });
            data.organisations.unshift(createdOrganisation);
            store.writeQuery({ query: FIND_ORGANISATIONS, data });
          },
        })
        .then(() => {
          this.$emit("updateUserUuids");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
  },
};
</script>
