<template>
  <div class="grid gap-6">
    <TextField
      v-model="name"
      :label="translations.nameLabel"
      :placeholder="translations.namePlaceholder"
      :focus="name === null"
      @input="updateName"
    />
    <SelectField
      v-model="countryUuid"
      :label="translations.countryLabel"
      @input="updateCountryUuid"
    >
      <option value="-1" selected disabled>
        {{ translations.countryPlaceholder }}
      </option>
      <option
        v-for="country in countryOptions"
        :key="country.uuid"
        :value="country.uuid"
        :selected="countryUuid === country.uuid"
      >
        {{ countryName(country) }}
      </option>
    </SelectField>
    <ListSelector
      :label="translations.addUsers"
      :add-text="translations.addUser"
      :item-options="availableUsers"
      :selected-item-options="selectedUsers"
      @updateSelectedItems="updateUserUuids"
    />
  </div>
</template>

<script>
import ListSelector from "@/components/common/ListSelector";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    ListSelector,
  },
  props: {
    currentOrganisation: {
      type: Object,
      default: () => {
        return {
          name: null,
          country: {
            uuid: "-1",
            code: null,
          },
          countries: [],
          users: [],
        };
      },
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      autofocusName: false,
      name: this.currentOrganisation.name,
      countryUuid: this.currentOrganisation.country.uuid,
      users: this.currentOrganisation.users,
      translations: {
        nameLabel: this.$t(
          "components.userManagement.organisations.organisationsForm.name",
        ),
        namePlaceholder: this.$t(
          "components.userManagement.organisations.organisationsForm.namePlaceholder",
        ),
        countryLabel: this.$t(
          "components.userManagement.organisations.organisationsForm.location",
        ),
        countryPlaceholder: this.$t(
          "components.userManagement.organisations.organisationsForm.locationPlaceholder",
        ),
        addUsers: this.$t(
          "components.userManagement.stores.storesForm.addUsers",
        ),
        addUser: this.$t("components.userManagement.stores.storesForm.addUser"),
      },
    };
  },

  computed: {
    availableUsers() {
      return this.userOptions.map((user) => ({
        title: this.userTitle(user),
        subTitle: user.role?.description || user.role?.name,
        key: user.uuid,
      }));
    },
    selectedUsers() {
      return this.users.map((user) => ({
        title: this.userTitle(user),
        subTitle: user.role?.description || user.role?.name,
        key: user.uuid,
      }));
    },
  },

  methods: {
    userTitle(user) {
      return user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.email;
    },
    updateName() {
      this.$emit("updateName", this.name);
    },
    updateCountryUuid() {
      this.$emit("updateCountryUuid", this.countryUuid);
    },
    updateUserUuids(selectedItems) {
      const selectedUserUuids = selectedItems.map((item) => item.key);
      this.$emit("updateUserUuids", selectedUserUuids);
    },
    countryName(country) {
      return countries.getName(country.code, "en");
    },
  },
};
</script>
