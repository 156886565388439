<template>
  <TableRowFrame @emitClick="open">
    <template v-slot:colOne>
      <div v-if="franchisee" class="flex items-center">
        <UserAvatar variant="primary" :user="franchisee" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            {{ franchisee.firstName }}
            {{ franchisee.lastName }}
          </div>
          <div class="truncate text-neutral-400">
            <div class="hidden md:block">
              {{ franchisee.email }}
            </div>
            <div class="md:hidden">{{ organisation.name }}</div>
          </div>
        </div>
      </div>
      <div v-if="!franchisee" class="flex items-center">
        <UserAvatar variant="danger" :user="{ firstName: `?` }" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            No franchisee selected
          </div>
          <div class="truncate text-danger-400">Complete details</div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div class="truncate flex items-center text-sm">
        <country-flag :country="organisation.country.code" size="small" />
        <span class="ml-2">
          {{ organisation.name }}
        </span>
      </div>
    </template>
    <template v-slot:colThree>
      <div class="text-sm">{{ organisation.stores.length }} stores</div>
    </template>
    <template v-slot:colFour>
      <UserAvatarList :users="organisation.users" />
    </template>
    <template v-slot:colFive>
      <OverflowMenu class="flex items-center" :items="menuItems" />
    </template>
  </TableRowFrame>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";
import UserAvatarList from "@/components/common/UserAvatarList";
import TableRowFrame from "@/components/user-management/TableRowFrame";
import OverflowMenu from "@/components/common/OverflowMenu";
import findFranchisee from "@/utils/findFranchisee";

export default {
  components: {
    OverflowMenu,
    UserAvatar,
    UserAvatarList,
    TableRowFrame,
  },

  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          name: this.$t("global.viewDetails"),
          showIf: true,
          callback: () => {
            this.open();
          },
        },
      ],
    };
  },

  computed: {
    franchisee() {
      return findFranchisee(this.organisation.users, null);
    },
  },

  methods: {
    open() {
      this.$emit("open", this.organisation);
    },
  },
};
</script>
