<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        v-if="!editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ translations.organisationDetails }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>

      <button
        v-if="editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ translations.editOrganisation }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent v-if="!editMode">
        <div class="flex justify-between">
          <div v-if="franchisee" class="mb-8 flex items-center overflow-hidden">
            <UserAvatar variant="primary" size="lg" :user="franchisee" />
            <div class="min-w-0 pl-4 text-sm">
              <div class="truncate text-lg font-medium text-neutral-900">
                {{ franchisee.firstName }}
                {{ franchisee.lastName }}
              </div>
              <div class="truncate text-neutral-400">
                {{ franchisee.email }}
              </div>
            </div>
          </div>
          <div
            v-if="!franchisee"
            class="mb-8 flex cursor-pointer items-center"
            @click="openEditMode"
          >
            <UserAvatar variant="danger" size="lg" :user="{}" />
            <div class="min-w-0 pl-4 text-sm">
              <div class="truncate text-lg font-medium text-neutral-900">
                {{ translations.noFranchiseeSelected }}
              </div>
              <div class="truncate text-danger-400">
                {{ translations.completeDetails }}
              </div>
            </div>
          </div>
          <div class="ml-4 flex flex-shrink-0 items-start">
            <BaseButton
              v-if="$can('write:organisations')"
              size="medium"
              variant="outline"
              @buttonClick="openEditMode"
            >
              <template #iconCenter>
                <IconEdit class="text-neutral-300" />
              </template>
            </BaseButton>
          </div>
        </div>
        <div class="flex flex-wrap text-sm">
          <div class="mb-4 w-1/2">
            <div class="font-medium">
              {{ translations.organisation }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ organisation.name }}
            </div>
          </div>
          <div class="mb-4 w-1/2">
            <div class="font-medium">
              {{ translations.registeredIn }}
            </div>
            <div class="flex items-center text-sm-relaxed text-neutral-400">
              <country-flag :country="organisation.country.code" size="small" />
              <span class="ml-2">{{ countryName }}</span>
            </div>
          </div>
          <div class="w-1/2">
            <div class="font-medium">
              {{ translations.stores }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ `${organisation.stores.length} ${translations.stores}` }}
            </div>
          </div>
          <div class="w-1/2">
            <div class="font-medium">
              {{ translations.createdAt }}
            </div>
            <div class="text-sm-relaxed text-neutral-400">
              {{ since() }}
            </div>
          </div>
        </div>
      </ModalSideContent>
      <ModalSideContent v-if="!editMode && organisation.users.length > 0">
        <div class="pr-5 text-xs font-medium uppercase">
          {{ translations.users }}
        </div>
        <UserList :users="organisation.users" />
      </ModalSideContent>

      <ModalSideContent v-if="editMode" class="flex-grow overflow-y-scroll">
        <OrganisationsForm
          :current-organisation="organisation"
          :country-options="countryOptions"
          :user-options="userOptions"
          @updateName="updateName"
          @updateCountryUuid="updateCountryUuid"
          @updateUserUuids="updateUserUuids"
        />
        <div
          v-if="formError"
          class="bg-danger-0 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </ModalSideContent>

      <ModalSideActions v-if="editMode" class="justify-between">
        <div class="text-sm text-neutral-900">
          <div v-if="!disbandConfirmationVisible">
            <button
              :class="['focus:outline-none hidden font-medium sm:flex']"
              @click="showDisbandConfirmation"
            >
              {{ translations.deleteOrganisation }}
            </button>
          </div>
          <div v-else>
            {{ $t("global.areYouSure") }}
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="deleteOrganisation"
            >
              {{ $t("global.yes") }}
            </button>
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="hideDisbandConfirmation"
            >
              {{ $t("global.no") }}
            </button>
          </div>
        </div>
        <div class="flex">
          <BaseButton
            :text="$t(`global.cancel`)"
            size="medium"
            variant="outline"
            @buttonClick="closeModal"
          ></BaseButton>

          <BaseButton
            :text="$t(`global.saveChanges`)"
            size="medium"
            variant="fill"
            class="ml-2"
            @buttonClick="submit"
          >
          </BaseButton>
        </div>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";

import OrganisationsForm from "@/components/user-management/organisations/OrganisationsForm";
import UserAvatar from "@/components/common/UserAvatar";
import UserList from "@/components/user-management/UserList";

import UPDATE_ORGANISATION from "@/graphql/UpdateOrganisation.gql";
import DELETE_ORGANISATION from "@/graphql/DeleteOrganisation.gql";
import FIND_ORGANISATIONS from "@/graphql/FindOrganisations.gql";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import findFranchisee from "@/utils/findFranchisee";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    OrganisationsForm,
    UserAvatar,
    UserList,
  },

  props: {
    organisation: {
      type: Object,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formError: null,
      editMode: false,
      countries: null,
      editedName: this.organisation.name,
      editedCountryUuid: this.organisation.country.uuid,
      editedUserUuids: this.organisation.users.map((user) => user.uuid),
      disbandConfirmationVisible: false,
      translations: {
        organisationDetails: this.$t(
          "components.userManagement.organisations.organisationsModalDetails.organisationDetails",
        ),
        organisation: this.$t(
          "components.userManagement.organisations.organisationsModalDetails.organisation",
        ),
        registeredIn: this.$t(
          "components.userManagement.organisations.organisationsModalDetails.registeredIn",
        ),
        createdAt: this.$t("global.createdAt"),
        users: this.$t("global.users"),
        stores: this.$t("global.stores"),
        noFranchiseeSelected: this.$t(
          "components.userManagement.organisations.noFranchiseeSelected",
        ),
        completeDetails: this.$t("global.completeDetails"),
        editOrganisation: this.$t(
          "components.userManagement.organisations.organisationsModalDetails.editOrganisation",
        ),
        deleteOrganisation: this.$t(
          "components.userManagement.organisations.organisationsModalDetails.deleteOrganisation",
        ),
      },
    };
  },

  computed: {
    countryName() {
      return countries.getName(
        this.organisation.country.code,
        this.$i18n.locale,
      );
    },
    franchisee() {
      return findFranchisee(this.organisation.users, null);
    },
  },

  methods: {
    since() {
      const date = new Date(parseInt(this.organisation.createdAt));
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    closeModal() {
      this.$emit("close");
    },
    openEditMode() {
      this.editMode = true;
    },
    closeEditMode() {
      this.editMode = false;
      this.formError = null;
    },
    updateName(organisationName) {
      this.editedName = organisationName;
    },
    updateCountryUuid(organisationCountryUuid) {
      this.editedCountryUuid = organisationCountryUuid;
    },
    updateUserUuids(organisationUserUuids) {
      this.editedUserUuids = organisationUserUuids;
      this.$emit("updateUserUuids");
    },
    showDisbandConfirmation() {
      this.disbandConfirmationVisible = true;
    },
    hideDisbandConfirmation() {
      this.disbandConfirmationVisible = false;
    },
    deleteOrganisation() {
      this.$apollo
        .mutate({
          mutation: DELETE_ORGANISATION,
          variables: {
            uuid: this.organisation.uuid,
          },
          update: (store, { data: { deletedOrganisation } }) => {
            const data = store.readQuery({
              query: FIND_ORGANISATIONS,
            });

            const index = data.organisations.findIndex(
              (organisation) => organisation.uuid == deletedOrganisation.uuid,
            );

            if (index !== -1) {
              data.organisations.splice(index, 1);

              store.writeQuery({
                query: FIND_ORGANISATIONS,
                data,
              });
            }
          },
        })
        .then(() => {
          this.$emit("updateUserUuids");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: UPDATE_ORGANISATION,
          variables: {
            uuid: this.organisation.uuid,
            name: this.editedName,
            countryUuid: this.editedCountryUuid,
            userUuids: this.editedUserUuids,
          },
        })
        .then(() => {
          this.$emit("updateUserUuids");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
  },
};
</script>
